const progress = (meta) => {

    let progress = 5;
    if(meta.completed){
        progress = 100
    } else if(meta.steps.current === 11) {
        progress = 95;
    } else if(meta.steps.current === 10) {
        progress = 85;
    } else if(meta.steps.current === 8) {
        progress = 70;
    } else if(meta.steps.current === 7) {
        progress = 60;
    } else if(meta.steps.current === 6) {
        progress = 55;
    } else if(meta.steps.current === 5) {
        progress = 40;
    } else if(meta.steps.current === 4) {
        progress = 35;
    } else if(meta.steps.current === 3) {
        progress = 25;
    } else if(meta.steps.current === 2) {
        progress = 15;
    } else if(meta.steps.current === 1) {
        progress = 10;
    } else {
        progress = 5;
    }
    console.log('---->',meta.steps.current, progress)
    return progress;

}
export default progress;