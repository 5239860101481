import React, { useEffect, useState } from "react";
import { Select, Col } from "antd";
import moment from "moment";
import checkStatus from "../api/getAvailability";
import addOn from "../api/addOn";

const age_range = [
    {
        label: "21-24",
        value: moment().subtract(22.5, "years").format("YYYY-MM-DD"),
    },
    {
        label: "25-26",
        value: moment().subtract(25.5, "years").format("YYYY-MM-DD"),
    },
    { label: "27", value: moment().subtract(27, "years").format("YYYY-MM-DD") },
    {
        label: "28-29",
        value: moment().subtract(28.5, "years").format("YYYY-MM-DD"),
    },
    {
        label: "30+",
        value: moment().subtract(31, "years").format("YYYY-MM-DD"),
    },
];

const SelectAgeField = (props) => {
    const { setState, actionProvider, meta, disable } = props;
    const [age, setAge] = useState(meta.age || null);

    useEffect(() => {
        if (age) {
            setState((state) => ({
                ...state,
                meta: Object.assign(meta, { age: age }),
            }));

            actionProvider.handleHello("Hang tight! We are getting the best result for you..",{});
            actionProvider.handleStartLoader()
            checkStatus(meta).then((result) => {
                actionProvider.handleStopLoader()
                if (result?.data?.ota_vehavailraters?.errors?.error) {
                    actionProvider.handleHello(
                        result?.data?.ota_vehavailraters?.errors?.error
                            ?.shorttext
                    );
                    actionProvider.handleError("You can select a topic below ", {delay:2000})
                } else {
                    addOn(meta).then(result=>{
                        setState((state) => ({
                            ...state,
                            available_add_on: result?.data,
                        }));
                    }).catch(err=>{
                        console.log(err);
                    })
                    setState((state) => ({
                        ...state,
                        car_list: result?.data?.ota_vehavailraters,
                    }));
                    actionProvider.handleStopLoader()

                    actionProvider.handleCarList(
                        "Choose a car?"
                    );
                }
            });
        }
    }, [age]);

    const handleAge = (value, obj) => {
        console.log(`selected ${value}`, obj);
        setAge(obj);
    };

    return (
        <Col className={"w-full"}>
        <Select
            className="w-full mb-1"
            showSearch
            placeholder="Please Select an Age"
            optionFilterProp="children"
            onChange={handleAge}
            value={age?.value}
            disabled={disable}
            filterOption={(input, option) =>
                (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            options={age_range}
            size="large"
        />
        </Col>
    );
};

export default SelectAgeField;
