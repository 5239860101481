import axios from 'axios';

function openai(url, text){
    return new Promise((resolve, reject)=>{
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data : {text: text}
        };

        axios.request(config)
            .then((response) => {
                resolve(response?.data)
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })

}
export default openai;


