import React, {useState} from "react";
import {Card, Button, Col, Row} from "antd";
import events from '../fields/handler'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

let object = {
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/SunHat-3.jpg",
        description: "Eliminate your excess and protect yourself against collisions, theft and accidental damage.",
        quantity: 1,
        name: "SuperCove",
        currencycode:"",
        price:"",
        title:"SuperCove",
        rate:"",
        id: "59"
    }

const AddOnDefault = (props) => {
    const { setState, actionProvider, meta, disable } = props;

    let defaultAddOn = meta?.selectedCar?.coverage?.find(o=>o?.id === "59")

    if(!defaultAddOn){
        defaultAddOn = {}
    }
    const [o, setO] = useState({ ...object, ...defaultAddOn})
    const handleAddOnSelectYes = () => {
        let addOnSelection = {};
        addOnSelection[o.id] = o;
        setState((state) => ({
            ...state,
            meta: Object.assign(meta, {
                selectedAddOn: {...meta.selectedAddOn, ...addOnSelection},
                steps: {current: 10, previous: meta.steps.current}
            }),
        }));
        events(props);
        // actionProvider.handleAddOnOption(
        //     "Would you like to add any additional add-ons to your rental? Please select from the following options:"
        // );
    }

    const handleAddOnSelectNo = () => {
        setState((state) => ({
            ...state,
            meta: Object.assign(meta, {
                steps: {current: 10, previous: meta.steps.current}
            }),
        }));
        events(props);
        // actionProvider.handleAddOnOption(
        //     "Would you like to add any additional add-ons to your rental? Please select from the following options:"
        // );
    }

    return (
        <React.Fragment>
            <Card
                className="add-on-details-card mb-2"
                bordered={true}
                cover={
                    <Col className="text-center">
                        <img
                            className="h-35 w-full bg-cover bg-center"
                            src={o.img}
                            alt={o.name}
                        />
                    </Col>
                }
            >
                <Col className="flex flex-col h-45">
                    <Col className="w-full mb-3">
                        <Col className="text-base font-bold">
                            {o.name}
                        </Col>

                        <Col className="text-sm font-normal line-clamp-4">
                            {o.description}
                        </Col>
                    </Col>

                    <div className="flex flex-1" />

                    <Col className="text-base font-bold mb-3">
                        {`${o.totalwithtax} (${meta?.selectedCar?.vehiclecharges?.currency})`}
                    </Col>

                </Col>
            </Card>
            <Row gutter={[12, 8]}>
                        <Button
                            disabled={disable}
                            // key={i}
                            className="mr-1"
                            onClick={handleAddOnSelectYes}
                            type="primary-outline"
                            shape="round"
                            size="large"
                        >
                            Yes, continue
                        </Button>
                <Button
                            disabled={disable}
                            // key={i}
                            className="mr-1"
                            onClick={handleAddOnSelectNo}
                            type="primary-outline"
                            shape="round"
                            size="large"
                        >
                    No, thanks
                        </Button>

            </Row>
        </React.Fragment>
    );
};
export default AddOnDefault;
