import { createChatBotMessage } from "react-chatbot-kit";
import CarList from "./widgets/CarList";
import { RobotOutlined } from "@ant-design/icons";
import SelectPickField from "./fields/select-pick";
import SelectDropField from "./fields/select-drop";
import SelectPickDrop from "./fields/select-pick-drop";
import SelectAgeField from "./fields/select-age";
import DatePick from "./fields/date-pick";
import TimePick from "./fields/time-pick";
import DateTimePick from "./fields/date-time-pick";
import DateDrop from "./fields/date-drop";
import TimeDrop from "./fields/time-drop";
import DateTimeDrop from "./fields/date-time-drop";
import Loader from "./fields/loader";
import AddOnList from "./fields/add-on-list";
import DefaultAddOnList from "./fields/add-on-default";
import AppHeader from "./widgets/AppHeader";
import Options from "./components/options";
import handle from "./components/handle";
import moment from "moment";

const botName = "Bob";
const config = {
    initialMessages: [
        createChatBotMessage(
            `Hi! I'm ${botName}, Thrifty' virtual assistant, here to help you with your upcoming booking.`
        ),
        createChatBotMessage(
            `Before we begin, note your information is treated according to our privacy policy, which you can view at https://www.hertz.com/rentacar/privacypolicy.`,
            { delay: 1000 }
        ),
        createChatBotMessage(
            // `Ready to start? Select from an option below or ask me a simple question.`,
            `Ready to start? Select from an option below.`,
            { delay: 2000, widget: "option-reservation" }
        ),
    ],
    botName: botName,
    customStyles: {
        botMessageBox: {
            backgroundColor: "#007BC3b3",
            color: "#f2f4f7",
        },
        chatButton: {
            backgroundColor: "#007BC3",
        },

    },
    // meta format
    state: {
        source_list: [],
        destination_list: [],
        car_list: {},
        add_on_list: [],
        available_add_on: {},
        // messages: [],
        meta: {
            bot: {},
            progress: 0,
            pickUpCode: null,
            dropUpCode: null,
            pickUpDate: null,
            pickUpTime: null,
            dropUpDate: null,
            dropUpTime: null,
            age: null,
            selectedCar: {},
            selectedAddOn: {},
            customerDetails: {},
            otherInformation: {},
            completed: false,
            steps: { current: 0, previous: 0 },
        },
    },
    customComponents: {
        header: (actionProvider) => (
            <AppHeader config={config} actionProvider={actionProvider} />
        ),
        botAvatar: (props) => (
            <RobotOutlined
                style={{
                    width: "50px",
                    height: "40px",
                    color: "#000",
                    borderRadius: "50%",
                    fontSize: "1.2rem",
                    marginRight: "12.5px",
                    backgroundColor: "#d8d8d8",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                {...props}
            />
        ),
    },
    widgets: [
        // custom type field
        {
            widgetName: "select-pick-field",
            widgetFunc: (props) => (
                <SelectPickField
                    disable={props.meta.steps.current !== 1}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "select-drop-field",
            widgetFunc: (props) => (
                <SelectDropField
                    disable={props.meta.steps.current !== 3}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "select-pick-drop-field",
            widgetFunc: (props) => <SelectPickDrop pick_disable={props.meta.steps.current !== 1} {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "date-pick-field",
            widgetFunc: (props) => <DatePick {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "time-pick-field",
            widgetFunc: (props) => <TimePick {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "date-time-pick-field",
            widgetFunc: (props) => (
                <DateTimePick
                    disable={props.meta.steps.current !== 4}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "date-drop-field",
            widgetFunc: (props) => <DateDrop {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "time-drop-field",
            widgetFunc: (props) => <TimeDrop {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "date-time-drop-field",
            widgetFunc: (props) => (
                <DateTimeDrop
                    disable={props.meta.steps.current !== 5}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "select-age-option",
            widgetFunc: (props) => (
                <Options
                    disable={props.meta.steps.current !== 7}
                    {...props}
                    options={[
                        {
                            title: "21-24",
                            handle: () =>
                                handle.handleAgeOption(props, {
                                    label: "21-24",
                                    value: moment()
                                        .subtract(22.5, "years")
                                        .format("YYYY-MM-DD"),
                                }),
                        },
                        {
                            title: "25-26",
                            handle: () =>
                                handle.handleAgeOption(props, {
                                    label: "25-26",
                                    value: moment()
                                        .subtract(25.5, "years")
                                        .format("YYYY-MM-DD"),
                                }),
                        },
                        {
                            title: "27",
                            handle: () =>
                                handle.handleAgeOption(props, {
                                    label: "27",
                                    value: moment()
                                        .subtract(27, "years")
                                        .format("YYYY-MM-DD"),
                                }),
                        },
                        {
                            title: "28-29",
                            handle: () =>
                                handle.handleAgeOption(props, {
                                    label: "28-29",
                                    value: moment()
                                        .subtract(28.5, "years")
                                        .format("YYYY-MM-DD"),
                                }),
                        },
                    ]}
                />
            ),
            //
            //
            // <SelectAgeField disable={props.meta.steps.current !== 7} {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "loader",
            widgetFunc: (props) => <Loader {...props} />,
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },

        {
            widgetName: "car-list",
            widgetFunc: (props) => (
                <CarList disable={props.meta.steps.current !== 8} {...props} />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "add-on-list",
            widgetFunc: (props) => (
                <AddOnList
                    disable={props.meta.steps.current !== 10}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "add-on-default",
            widgetFunc: (props) => (
                <DefaultAddOnList
                    disable={props.meta.steps.current !== 9}
                    {...props}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },

        {
            widgetName: "option-reservation",
            widgetFunc: (props) => (
                <Options
                    disable={props.meta.steps.current > 0 || props.meta.steps.current === -1}
                    {...props}
                    options={[
                        {
                            title: "Make a reservation",
                            handle: () => handle.initiateReservation(props),
                        },
                        {
                            title: "Ask a question",
                            handle: () => handle.initiateFaq(props),
                        },
                    ]}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "option-error",
            widgetFunc: (props) => (
                <Options
                    {...props}
                    options={[
                        {
                            title: "Change pick-up and drop-off details",
                            handle: () => handle.handlePickDropDate(props),
                        },
                        {
                            title: "Start a new booking",
                            handle: () => handle.handleStartBooking(props),
                        },
                        {
                            title: "Change location",
                            handle: () => handle.handleBooking(props),
                        },
                    ]}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "option-location",
            widgetFunc: (props) => (
                <Options
                    disable={props.meta.steps.current !== 2}
                    {...props}
                    options={[
                        {
                            title: "Yes, continue",
                            handle: () => handle.sameLocation(props),
                        },
                        {
                            title: "No, change drop-off location",
                            handle: () => handle.changeLocation(props),
                        },
                    ]}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "option-add-on",
            widgetFunc: (props) => (
                <Options
                    {...props}
                    options={[
                        {
                            title: "Yes",
                            handle: () => handle.addOnOptionYes(props),
                        },
                        {
                            title: "No",
                            handle: () => handle.addOnOptionNo(props),
                        },
                    ]}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
        {
            widgetName: "option-age",
            widgetFunc: (props) => (
                <Options
                    disable={props.meta.steps.current !== 6}
                    {...props}
                    options={[
                        {
                            title: "Yes",
                            handle: () => handle.ageOptionYes(props),
                        },
                        {
                            title: "No",
                            handle: () => handle.ageOptionNo(props),
                        },
                    ]}
                />
            ),
            mapStateToProps: [
                "meta",
                "source_list",
                "destination_list",
                "car_list",
                "available_add_on",
            ],
        },
    ],
};

export default config;
