import checkStatus from "../api/getAvailability";
import addOn from "../api/addOn";

const handler = (props) => {
    const { setState, actionProvider, meta = {} } = props;
    let steps = meta.steps || {};
    console.log('Meta: ',meta, steps)
    if(steps.current === -1){
        actionProvider.handleHello(
            "How may I assist you today? Please provide your query below.",
            {delay: 1000});
    } else if(steps.current === 1 ){
        actionProvider.handlePickUpCode(
            "Please select your pick-up location.",
            {delay: 1000});
    } else if(steps.current === 2){
        actionProvider.handleDropOffOption(
            "Is your drop-off location the same as your pick-up location?",
            {delay: 1000})
    } else if(steps.current === 3){
        actionProvider.handleDropUpCode(
            "Please select your drop-off location.",
            {delay: 1000})
    } else if(steps.current === 4){
        actionProvider.handleDateTimePick(
            "When do you intend to make this booking? Could you please select your pick-up date and time?",
            {delay: 1000})
    } else if(steps.current === 5){
        console.log('----------->>>>>>>>')
        actionProvider.handleDateTimeDrop(
            "Great! How long you will be using the car?  Could you please tell drop off date and time?",
            {delay: 1000})
    } else if(steps.current === 6){
            actionProvider.handleAgeOption(
                "Are you 30 years of age or older?",
                {delay: 1000})
    } else if(steps.current === 7){
            actionProvider.handleAge(
                "Which age group do you belong to? Please select one of the following options: ",
                {delay: 1000})
    } else if(steps.current === 8) {
        actionProvider.handleHello("Hang tight! We are getting the best result for you.",{});
        actionProvider.handleStartLoader({delay: 1000})
        checkStatus(meta).then((result) => {
            console.log('-------------', result)
            if(!result.meta.success) {
                    actionProvider.handleStopLoader();
                    const message = typeof result.meta?.message === "string" ? result.meta?.message : "Something wrong";
                    const lowercaseMessage = message.toLowerCase();
                    const sentence = lowercaseMessage.charAt(0).toUpperCase() + lowercaseMessage.slice(1).toLowerCase();
                    actionProvider.handleHello(sentence);
                    actionProvider.handleError("Please try again by selecting one of the following options.", {delay:1000})
                } else {
                setState((state) => ({
                    ...state,
                    car_list: result?.data
                }));
                actionProvider.handleStopLoader()
                actionProvider.handleCarList(
                    "Please select your desired car from the rental car results provided.", {});
            }
        }).catch(err=>{
            actionProvider.handleStopLoader()
            actionProvider.handleHello(
                "Something wrong"
            );
            actionProvider.handleError("You can select a topic below ", {delay:2000})
        });
    } else if(steps.current === 10 || steps.current === 9) {
        actionProvider.handleStartLoader()
        addOn(meta).then(addOnResponse=>{
            actionProvider.handleStopLoader()
            setState((state) => ({
                ...state,
                available_add_on: addOnResponse?.data
            }));
            actionProvider.handleAddOnOption(
                "Would you like to add any additional add-ons to your rental? Please select from the following options:",
                { delay: 1000 }
            );

        }).catch(err=>{
            console.log(err);
        })
    } else {

    }
    // if(!meta.pickUpCode && !meta.dropUpCode) {

    // } else if(!meta.pickUpCode) {
    // actionProvider.handlePickUpCode(
    //     "Please select your pick-up location.",
    //     {delay: 1000});
    // } else if(!meta.dropUpCode) {
    // actionProvider.handlePickUpCode(
    //     "Please select your pick-up location.",
    //     {delay: 1000})
    // } else if((!meta.pickUpDate && !meta.pickUpTime) || (meta.pickUpDate === "Invalid date" && meta.pickUpTime === "Invalid date")) {
    //     actionProvider.handleDateTimePick(
    //         "When do you intend to make this booking? Could you please specify your pick-up date and time?",
    //         {delay: 1000})
    // } else if(!meta.pickUpDate || meta.pickUpDate === "Invalid date") {
    //     actionProvider.handleDatePick(
    //         "Please specify your pick-up date?",
    //         {delay: 1000})
    // } else if(!meta.pickUpTime || meta.pickUpTime === "Invalid date") {
    //     actionProvider.handleTimePick(
    //         "Please specify your pick-up time?",
    //         {delay: 1000})
    // } else if((!meta.dropUpDate && !meta.dropUpTime) || (meta.dropUpDate === "Invalid date" && meta.dropUpTime === "Invalid date")) {
    //     actionProvider.handleDateTimeDrop(
    //         "Great! How long you will be using the car?  Could you please tell drop off date and time?",
    //         {delay: 1000})
    // } else if(!meta.dropUpDate || meta.dropUpDate === "Invalid date") {
    //     actionProvider.handleDateDrop(
    //         "please specify your drop off date?",
    //         {delay: 1000})
    // } else if(!meta.dropUpTime || meta.dropUpTime === "Invalid date") {
    //     actionProvider.handleTimeDrop(
    //         "please specify your drop off time?",
    //         {delay: 1000})
    // } else if(!meta.age) {
    //     actionProvider.handleAge(
    //         "Please select your age?",
    //         {delay: 1000})
    // }
}
export default handler;
