import React from "react";
import { Card, Button, Col, Row, Badge } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import events from '../fields/handler';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const CarList = (props) => {
    const { setState, actionProvider, meta, car_list, available_add_on, disable } = props;

    let list = car_list
    const handleCarSelect = (info, imageIndex) => {
        setState((state) => ({
            ...state,
            meta: Object.assign(meta, {
                selectedCar: Object.assign({}, info),
                steps: {current: 9, previous: meta.steps.current}
            }),
        }));
        let available_add_on_default = info?.coverage?.find((o) => o.id === "59");
        let available_add_on_list = available_add_on;

        if (available_add_on_default || available_add_on_list) {
            // const addOnHotAvailable = available_add_on_default;
            if (available_add_on_default) {
                actionProvider.handleDefaultAddOnOption(
                    "You can make your ride safer with our most opted addon, Super Cover. It protects you against collisions, theft, and accidental damage.",
                    { delay: 1000 }
                );
            } else {
                setState((state) => ({
                    ...state,
                    meta: Object.assign(meta, {
                        steps: {current: 10, previous: meta.steps.current}
                    }),
                }));
                events(props);
                // actionProvider.handleAddOnOption(
                //     "Would you like to add any additional add-ons to your rental? Please select from the following options:",
                // { delay: 1000 }
                // );
            }
        } else {
            // actionProvider.handleHello("Add-on option not available");
            actionProvider.handleResult(
                "Thank you for taking the time to share your information. We have successfully saved it.",
                { delay: 1000 }
            );
            // actionProvider.handleStartLoader(props);
            actionProvider.handleCompleted();
            actionProvider.handleResult(
                "Please refer to the summary provided above.",
                { delay: 2000 }
            );
        }
    };

    return (
        <React.Fragment>
            <Swiper
                className="car-list-swiper"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={16}
                slidesPerView={1}
                navigation
                // pagination
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
            >
                {list.map((o, i) => {
                    // const vehicleInfo = o.vehavailcore.vehicle;
                    // const totalCharges = o.vehavailcore.totalcharge;
                    // const reference = o.vehavailcore.reference;
                    // const rentalCharges = o.vehavailcore.rentalrate;
                    // let imageUrl;
                    // const className = vehicleInfo?.vehclass?.size;
                    // let vehicleName = vehicleInfo?.vehmakemodel?.name;
                    const carType = o?.fueltype === "Electric" ? "Electric Vehicle" : null;
                    // if (
                    //     vehicleName &&
                    //     vehicleName.toLowerCase() ===
                    //         "description not available"
                    // ) {
                    //     vehicleName = "A Hyundai i40 OR SIMILAR";
                    //     imageUrl = `https://images.hertz.com/MaxymiserVehicles/ZRGBSCMR.png`;
                    //     o.image_url = imageUrl;
                    //     o.name =
                    //         vehicleName &&
                    //         vehicleName
                    //             .split(" ")
                    //             .splice(1)
                    //             .join()
                    //             .replaceAll(",", " ");
                    // } else {
                    //     imageUrl = `https://images.hertz.com/vehicles/220x128/${vehicleInfo?.pictureurl}`;
                    //     o.image_url = imageUrl;
                    //     o.name =
                    //         vehicleName &&
                    //         vehicleName
                    //             .split(" ")
                    //             .splice(1)
                    //             .join()
                    //             .replaceAll(",", " ");
                    // }
                    return (
                        <SwiperSlide key={o.id}>
                            <Badge.Ribbon
                                text={carType}
                                color={carType ? "#088764" : "transparent"}
                                placement="start"
                            >
                                <Card
                                    className="car-details-card"
                                    // bordered={false}
                                    cover={
                                        <Col className="text-center">
                                            <img
                                                className="h-36 bg-auto bg-center"
                                                src={o.pictureurl}
                                                alt={o.name}
                                            />
                                        </Col>
                                    }
                                >
                                    <Col>
                                        <Col className="text-base line-clamp-1 font-bold">
                                            {o.vehclass || "-"}
                                        </Col>

                                        <Col className="">
                                            {o?.name ? (
                                                <span
                                                    className="text-base line-clamp-1"
                                                    align="bottom"
                                                    title={o.name}
                                                >
                                                    {o.name || "-"}

                                                    {/*<span className="text-sm font-medium text-gray-500 ml-1">*/}
                                                    {/*(*/}
                                                    {/*{totalCharges?.currencycode}*/}
                                                    {/*) / Day*/}
                                                    {/*</span>*/}
                                                </span>
                                            ) : (
                                                "-"
                                            )}
                                        </Col>
                                        <Col className="mb-3">
                                            {o?.vehiclecharges?.total ? (
                                                <span
                                                    className="text-xl font-bold"
                                                    align="bottom"
                                                >
                                                    {/*Approx. Total{" "}*/}
                                                    {
                                                        o?.vehiclecharges?.total
                                                    }
                                                    <span className="text-xl font-medium text-gray-500 ml-1">
                                                        (
                                                        {
                                                            o?.vehiclecharges?.currency
                                                        }
                                                        )
                                                    </span>
                                                </span>
                                            ) : (
                                                "-"
                                            )}
                                        </Col>

                                        <Col className="py-2 mb-3 border-x-0 border-y border-solid border-y-stone-500 mb-3">
                                            <Row
                                                className="w-full"
                                                align="middle"
                                                justify="center"
                                            >
                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/person.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                o?.passengerquantity ??
                                                                "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>

                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/feature_suitcase.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                o?.baggagequantity ??
                                                                "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>

                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/feature_transmission.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                o?.transmissiontype
                                                                    ? `${o?.transmissiontype}`.charAt(
                                                                          0
                                                                      )
                                                                    : "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col>
                                            <Button
                                                type={"primary-yellow font-normal"}
                                                disabled={disable}
                                                block
                                                // shape="round"
                                                onClick={() =>
                                                    handleCarSelect(o, i)
                                                }
                                                size="large"
                                                className="mt-2"
                                            >
                                                Select
                                            </Button>
                                        </Col>
                                    </Col>
                                </Card>
                            </Badge.Ribbon>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </React.Fragment>
    );
};
export default CarList;
