import React, { useEffect, useState } from "react";
import { DatePicker, Col } from "antd";
import moment from "moment";
import handler from "./handler";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const DatePick = (props) => {
    const { setState, actionProvider, meta } = props;
    const [date, setDate] = useState(dayjs().add(1,'days'));

    useEffect(()=>{
        if(date){
            setState((state) => ({
                ...state,
                meta: Object.assign(meta, {
                    pickUpDate: date.format("YYYY-MM-DD"),
                }),
            }));
            handler(props)
        }
    }, [date])

    const handleDate = (text) =>{
        console.log(`selected ${text}`)
        setDate(text);
    }

    return (
        <Col className="w-full">
            <DatePicker
                className="w-full"
                placeholder={"Select pick-up date"}
                onChange={handleDate}
                format={"DD-MM-YYYY"}
                use12Hours={true}
                disabledDate={(current) =>
                    current && current < moment().startOf("day")
                }
                defaultValue={date}
                size="large"
            />
        </Col>
    );
};

export default DatePick;
