import React from "react";
import openaiApi from "./api/openai";
import axios from "axios";
import async from "async";
import Loader from "./widgets/Loader";
import moment from "moment";
import events from "./fields/handler";
const botName = "Thrifty";

const handler = {
    // custom widgets handler
    pushValue: ({ setState }, message) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    },

    stopLoader: ({ setState, messages }) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages.slice(0, -1)],
        }));
    },

    startLoader: (props) => {
        handler.pushValue(props, { message: <Loader />, type: "bot" });
    },
};

const ActionProvider = (props) => {
    const { createChatBotMessage, setState, children } = props;

    const handleState = (payload) => {
        setState((prev) => ({
            ...prev,
            ...payload
        }))
    }

    const handleCustomWidgets = (message, options) => {
        const botMessage = createChatBotMessage(message, options);
        handler.pushValue(props, botMessage);
    };
    const handlePickUpCode = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "select-pick-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDropOffOption = (message, options) => {
        const botMessage = createChatBotMessage(message || "Please wait", {
            loading: true,
            terminateLoading: true,
            withAvatar: false,
            ...options,

            widget: "option-location",
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleDropUpCode = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "select-drop-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handlePickUpDropUpCode = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "select-pick-drop-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDatePick = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "date-pick-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleTimePick = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "time-pick-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDateTimePick = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "date-time-pick-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDateDrop = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "date-drop-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleTimeDrop = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "time-drop-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDateTimeDrop = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "date-time-drop-field",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleAge = (message, options) => {
        const defaultMessage = "";
        const botMessage = createChatBotMessage(message || defaultMessage, {
            ...options,
            widget: "select-age-option",
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleStartLoader = (option = {}) => {
        const botMessage = { message: <Loader />, type: "bot", ...option };
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleStopLoader = () => {
        setState((prev) => ({
            ...prev,
            messages: prev.messages.slice(0, -1),
        }));
    };
    const handleHello = (message, options = {}) => {
        const botMessage = createChatBotMessage(
            message ||
            `Hi! I'm ${botName}, Hertz' virtual assistant, here to help you with your upcoming booking.`,
            options
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleDob = (message) => {
        const botMessage = createChatBotMessage(message, {
            widget: "dob",
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleCarList = (message) => {
        const botMessage = createChatBotMessage(
            message || "Here's a nice dog picture for you!",
            {
                widget: "car-list",
                // delay: 2000,
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleAddOnList = (message, options) => {
        const botMessage = createChatBotMessage(
            message || "Here's a nice dog picture for you!",
            {
                ...options,
                widget: "add-on-list",
                delay: 1000,
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDefaultAddOnOption = (message, options = {}) => {
        const botMessage = createChatBotMessage(
            message || "Here's a nice dog picture for you!",
            {
                widget: "add-on-default",
                ...options
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleAddOnOption = (message, options = {}) => {
        const botMessage = createChatBotMessage(
            message || "Here's a nice dog picture for you!",
            {
                widget: "add-on-list",
                ...options
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleAgeOption = (message) => {
        const botMessage = createChatBotMessage(
            message || "Here's a nice dog picture for you!",
            {
                widget: "option-age",
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleTime = (message, options = {}) => {
        const botMessage = createChatBotMessage(
            message ||
            "When are you planning to make this booking? Can you please select you pick up date and time?",
            {
                widget: "time",
                ...options,
            }
        );

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleSelect = (message) => {
        const botMessage = createChatBotMessage(message, {
            widget: "select",
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleCompleted = () => {
        setState((prev) => ({
            ...prev,
            meta: Object.assign(prev.meta, { completed: true }),
        }));
    };

    const handleResult = (message, options) => {
        const botMessage = createChatBotMessage(message || "Please wait", {
            loading: true,
            terminateLoading: true,
            withAvatar: false,
            ...options,

            // widget: "result",
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    const handleError = (message, options) => {
        const botMessage = createChatBotMessage(message || "Please wait", {
            loading: true,
            terminateLoading: true,
            withAvatar: false,
            ...options,

            widget: "option-error",
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleSelectField = (message, type) => {
        const botMessage = createChatBotMessage(message || "Please wait", {
            loading: true,
            terminateLoading: true,
            withAvatar: false,

            widget: type,
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };
    const handleDefault = (text, action) => {
        let meta = null;
        async.waterfall([
            function one(callback){
                setState(prev=>{
                    meta = prev.meta;
                    return {
                        ...prev,
                        messages: [...prev.messages, { message: <Loader />, type: "bot" }]
                    }
                })
                setTimeout(()=>callback(null), 2000)
            },
            function(callback){
                if(meta.steps.current === -1){
                    console.log(text)
                    axios.post("https://development.procezo.com/api/custom", {
                        question: text,
                        email: "thrifty@gmail.com"
                    }).then(res=>{
                        handler.stopLoader(props);
                        setState(prev=>{
                            meta = prev.meta;
                            return {
                                ...prev,
                                messages: [...prev.messages, { message: res.data?.text, type: "bot" }]
                            }
                        })
                    }).catch(err=>{
                        handler.stopLoader(props);
                    })
                }
                else if(meta.steps.current === 0 || meta.steps.current === 1){
                    let prompt = `Identify Source, Destination, Pick-up Date, Pick-up time, Drop-off Date,Drop-off time.`;
                    prompt = `${prompt} Pass null if anything is not specified. Give the output in JSON format.`;
                    prompt = `${prompt} If you didn't understand the statement, pass null in all the above parameters in json.`;
                    prompt = `${prompt} Always give an output in JSON format. Statement: ${text}.`;
                    openaiApi(
                        "https://easycontent-dev.ibism.com/api/v1/hertz/openai",prompt).then((result) => {
                        console.log('response', result)
                        const message = result?.data?.choices[0]?.message?.content;
                        try {
                            // handler.stopLoader(props);
                            const json = JSON.parse(message);
                            let sourceArray, destinationArray;

                            // chatGPT output handler
                            const chatGPTResponse = {
                                source: json.Source || json.source,
                                destination: json.Destination || json.destination,
                                "pick-up-date":
                                    json["Pick-up Date"] ||
                                    json["pick-up Date"] ||
                                    json["Pick-up date"] ||
                                    json["pick-up date"],
                                "pick-up-time":
                                    json["Pick-up Time"] ||
                                    json["pick-up Time"] ||
                                    json["Pick-up time"] ||
                                    json["pick-up time"],
                                "drop-off-date":
                                    json["Drop-off Date"] ||
                                    json["drop-off Date"] ||
                                    json["Drop-off date"] ||
                                    json["drop-off date"],
                                "drop-off-time":
                                    json["Drop-off time"] ||
                                    json["drop-off Time"] ||
                                    json["Drop-off time"] ||
                                    json["drop-off time"],
                            };
                            let otherObj = {};
                            if (chatGPTResponse["pick-up-date"]) {
                                otherObj.pickUpDate = moment(
                                    chatGPTResponse["pick-up-date"]
                                ).format("2023-MM-DD");
                            }
                            if (chatGPTResponse["drop-off-date"]) {
                                otherObj.dropUpDate = moment(
                                    chatGPTResponse["drop-off-date"]
                                ).format("2023-MM-DD");
                            }
                            if (chatGPTResponse["pick-up-time"]) {
                                otherObj.pickUpTime = moment(
                                    chatGPTResponse["pick-up-time"]
                                ).format("hh:mm");
                            }
                            if (chatGPTResponse["drop-off-time"]) {
                                otherObj.pickUpDate = moment(
                                    chatGPTResponse["drop-off-time"]
                                ).format("hh:mm");
                            }
                            if (chatGPTResponse.source || chatGPTResponse.destination) {
                                // if (chatGPTResponse.source) {
                                setState((prev) => ({
                                    ...prev,
                                    meta: Object.assign(prev.meta, {
                                        bot: chatGPTResponse,
                                        ...otherObj,
                                    }),
                                }));
                                async.parallel(
                                    [
                                        function (cb) {
                                            if(chatGPTResponse.source) {
                                                openaiApi(
                                                    "https://easycontent-dev.ibism.com/api/v1/hertz/location",
                                                    chatGPTResponse.source
                                                )
                                                    .then((searchResponse) => {
                                                        sourceArray = searchResponse.data.d;
                                                        cb(null);
                                                    })
                                                    .catch((err) => cb(err));
                                            } else {
                                                sourceArray = [];
                                                cb(null);
                                            }

                                        },
                                        function (cb) {
                                            if(chatGPTResponse.destination) {
                                                openaiApi(
                                                    "https://easycontent-dev.ibism.com/api/v1/hertz/location",
                                                    chatGPTResponse.destination
                                                )
                                                    .then((searchResponse) => {
                                                        destinationArray =
                                                            searchResponse.data.d;
                                                        cb(null);
                                                    })
                                                    .catch((err) => cb(err));
                                            } else {
                                                destinationArray = []
                                                cb(null);
                                            }

                                        },
                                    ],
                                    function (err) {
                                        if (err) {

                                        } else {
                                            handler.stopLoader(props);
                                            if (sourceArray.length > 0 && destinationArray.length > 0) {
                                                // if (sourceArray.length > 0) {
                                                setState((prev) => {
                                                    return {
                                                        ...prev,
                                                        // meta: Object.assign(prev.meta, {
                                                        //     steps: {
                                                        //         current: 1,
                                                        //         previous: prev.meta.current
                                                        //     }
                                                        // }),
                                                        source_list: sourceArray,
                                                        destination_list: destinationArray,
                                                    };
                                                });
                                                if (sourceArray.length === 1 && destinationArray.length === 1) {
                                                    let botMessage1 =
                                                        createChatBotMessage(
                                                            `${
                                                                destinationArray[0].Name.split(
                                                                    ","
                                                                )[0]
                                                            }, I've always wanted to visit!`
                                                        );
                                                    let botMessage2 =
                                                        createChatBotMessage(
                                                            `${
                                                                sourceArray[0].Name.split(
                                                                    ","
                                                                )[0]
                                                            } ➜ ${
                                                                destinationArray[0].Name.split(
                                                                    ","
                                                                )[0]
                                                            }`,
                                                            { delay: 1000 }
                                                        );
                                                    let botMessage3 =
                                                        createChatBotMessage(
                                                            "When do you intend to make this booking? Could you please select your pick-up date and time?",
                                                            {
                                                                widget: "date-time-pick-field",
                                                                delay: 2000,
                                                            }
                                                        );
                                                    setState((prev) => {
                                                        return {
                                                            ...prev,
                                                            messages: [
                                                                ...prev.messages,
                                                                botMessage1,
                                                                botMessage2,
                                                                botMessage3,
                                                            ],
                                                            meta: Object.assign(
                                                                prev.meta,
                                                                {
                                                                    pickUpCode: sourceArray[0],
                                                                    steps: {
                                                                        current: 4,
                                                                        previous: prev.meta.steps.current
                                                                    },
                                                                    dropUpCode:
                                                                        destinationArray[0],
                                                                }
                                                            ),
                                                        };
                                                    });
                                                } else if (sourceArray.length > 1 && destinationArray.length === 1) {
                                                    let botMessage1 =
                                                        createChatBotMessage(
                                                            `${
                                                                destinationArray[0].Name.split(
                                                                    ","
                                                                )[0]
                                                            }, I've always wanted to visit!`
                                                        );
                                                    let message = `My records show that there are multiple records of your inputted pick-up location, ${chatGPTResponse.source}. Please select which location you're reffering to ?`;
                                                    const botMessage2 =
                                                        createChatBotMessage(message, {
                                                            // loading: true,
                                                            // terminateLoading: true,
                                                            // withAvatar: false,
                                                            delay: 1000,
                                                            widget: "select-pick-field",
                                                        });

                                                    setState((prev) => ({
                                                        ...prev,
                                                        messages: [
                                                            ...prev.messages,
                                                            botMessage1,
                                                            botMessage2,
                                                        ],
                                                        meta: Object.assign(prev.meta, {
                                                            dropUpCode: destinationArray[0],
                                                            steps: {
                                                                current: 1,
                                                                previous: prev.meta.steps.current
                                                            },
                                                        }),
                                                    }));
                                                } else if (
                                                    sourceArray.length === 1 &&
                                                    destinationArray.length > 1
                                                ) {
                                                    let message = `My records show that there are multiple records of your inputted drop-off location, ${chatGPTResponse.destination}. Please select which location you're reffering to ?`;
                                                    const botMessage =
                                                        createChatBotMessage(message, {
                                                            // loading: true,
                                                            // terminateLoading: true,
                                                            // withAvatar: false,
                                                            delay: 1000,
                                                            widget: "select-drop-field",
                                                        });

                                                    setState((prev) => ({
                                                        ...prev,
                                                        meta: Object.assign(prev.meta, {
                                                            pickUpCode: sourceArray[0],
                                                            steps: {
                                                                current: 3,
                                                                previous: prev.meta.steps.current
                                                            },
                                                        }),
                                                        messages: [
                                                            ...prev.messages,
                                                            botMessage,
                                                        ],
                                                    }));
                                                } else {
                                                    let message = `My records show that there are multiple records of your inputted pick-up location (${chatGPTResponse.source}), drop-off location (${chatGPTResponse.destination}). Please select which location you're reffering to ?`;
                                                    const botMessage =
                                                        createChatBotMessage(message, {
                                                            loading: true,
                                                            terminateLoading: true,
                                                            withAvatar: false,
                                                            // delay: 1000,
                                                            widget: "select-pick-field",
                                                        });

                                                    setState((prev) => ({
                                                        ...prev,
                                                        messages: [
                                                            ...prev.messages,
                                                            botMessage,
                                                        ],
                                                        meta: Object.assign(prev.meta, {steps: {
                                                                current: 1,
                                                                previous: prev.meta.current
                                                            }})
                                                    }));
                                                }
                                            } else if (sourceArray.length > 0 || destinationArray.length > 0) {
                                                const notExistSource = sourceArray.length === 0;
                                                const oneExistDestination = destinationArray.length === 1;
                                                const oneExistSource = sourceArray.length === 1;
                                                if (notExistSource) {
                                                    let botInitialMessage =
                                                        createChatBotMessage(
                                                            // `I'm sorry, it looks like we don't have information based on your inputted pick-up location.`
                                                            `Apologies, it looks like we couldn't find your pick up location.`
                                                        );
                                                    let message2 =
                                                        "Please select your pick-up location from the drop-down below";
                                                    const botMessage =
                                                        createChatBotMessage(message2, {
                                                            loading: true,
                                                            terminateLoading: true,
                                                            withAvatar: false,
                                                            delay: 1000,
                                                            widget: "select-pick-field",
                                                        });

                                                    setState((prev) => ({
                                                        ...prev,
                                                        messages: [
                                                            ...prev.messages,
                                                            botInitialMessage,
                                                            botMessage,
                                                        ],
                                                        meta: Object.assign(prev.meta, {dropUpCode: oneExistDestination ? destinationArray[0] : null, steps: {
                                                                current: 1,
                                                                previous: prev.meta.current
                                                            }})
                                                    }));
                                                } else {
                                                    let botInitialMessage =
                                                        createChatBotMessage(
                                                            `Apologies, it looks like we couldn't find your drop off location.`
                                                        );
                                                    let message2 =
                                                        "Please select your drop-off location from the drop-down below";
                                                    const botMessage =
                                                        createChatBotMessage(message2, {
                                                            loading: true,
                                                            terminateLoading: true,
                                                            withAvatar: false,
                                                            delay: 1000,
                                                            widget: "option-location",
                                                        });

                                                    setState((prev) => ({
                                                        ...prev,
                                                        messages: [
                                                            ...prev.messages,
                                                            botInitialMessage,
                                                            botMessage,
                                                        ],
                                                        meta: Object.assign(prev.meta, {pickUpCode: oneExistSource ? sourceArray[0] : null,steps: {
                                                                current: 2,
                                                                previous: prev.meta.current
                                                            }})
                                                    }));
                                                }
                                            } else {
                                                let botInitialMessage =
                                                    createChatBotMessage(
                                                        "I'm sorry, it looks like we don't have information based on your inputted pick-up location."
                                                    );
                                                let message2 =
                                                    "Please select your pick-up location from the drop-down below";
                                                const botMessage = createChatBotMessage(
                                                    message2,
                                                    {
                                                        loading: true,
                                                        terminateLoading: true,
                                                        withAvatar: false,
                                                        delay: 1000,
                                                        widget: "select-pick-field",
                                                    }
                                                );

                                                setState(prev => ({
                                                    ...prev,
                                                    meta: Object.assign(prev.meta, {steps: {
                                                            current: 1,
                                                            previous: prev.meta.current
                                                        }}),
                                                    messages: [
                                                        ...prev.messages,
                                                        botInitialMessage,
                                                        botMessage,
                                                    ]
                                                }))
                                            }
                                        }
                                    }
                                );
                            } else {
                                handler.stopLoader(props);
                                let botInitialMessage =
                                    createChatBotMessage(
                                        "I'm not sure what you mean, but I’m still learning so please let me try again. A short, direct sentence or keyword is easiest for me to understand."
                                    );
                                // let message2 =
                                //     "Would you like to select another pick-up location?";
                                // const botMessage = createChatBotMessage(
                                //     message2,
                                //     {
                                //         loading: true,
                                //         terminateLoading: true,
                                //         withAvatar: false,
                                //         delay: 1000,
                                //         // widget: "select-pick-field",
                                //     }
                                // );

                                setState(prev => ({
                                    ...prev,
                                    meta: Object.assign(prev.meta, {steps: {
                                            current: 1,
                                            previous: prev.meta.current
                                        }}),
                                    messages: [
                                        ...prev.messages,
                                        botInitialMessage,
                                        // botMessage,
                                    ]
                                }))
                            }
                        } catch (e) {
                            console.log('---------')
                            handler.stopLoader(props);
                            let botMessage = createChatBotMessage(
                                "I'm not sure what you mean, but I’m still learning so please let me try again. A short, direct sentence or keyword is easiest for me to understand.",
                                { delay: 1000 }
                            );
                            setState((prev) => ({
                                ...prev,
                                messages: [...prev.messages, botMessage]
                            }))
                            callback(null);
                        }


                    }).catch((err) => {
                        const botMessage = createChatBotMessage(err || "Error in process.");
                        handler.stopLoader(props);
                        setState(prev=>({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }));

                    });
                }
                else if(meta.steps.current === 2){
                    handler.stopLoader(props);
                    const message = text && text.toLowerCase();
                    if(message.includes("no") || message.includes("nah") || message.includes("nada") || message.includes("nope")){
                        const botMessage = createChatBotMessage("Please select your drop-off location.", {
                            delay: 1000,
                            widget: "select-drop-field",
                        });
                        setState((prev) => ({
                            ...prev,
                            meta: Object.assign(prev.meta,{steps: {current: 3, previous: prev.meta.steps.current}}),
                            messages: [...prev.messages, botMessage],
                        }));
                        callback(null)
                    } else if(message.includes("yes") || message.includes("yep") || message.includes("yeah")){
                        const botMessage = createChatBotMessage("When do you intend to make this booking? Could you please select your pick-up date and time?", {
                            delay: 1000,
                            widget: "date-time-pick-field",
                        });
                        setState((prev) => ({
                            ...prev,
                            meta: Object.assign(prev.meta,{dropUpCode: prev.meta.pickUpCode, steps: {current: 4, previous: prev.meta.steps.current}}),
                            messages: [...prev.messages, botMessage],
                        }));
                        callback(null)
                    } else {
                        let botMessage = createChatBotMessage(
                            "I apologize for any confusion. I'm not sure if you mean 'yes' or 'no'. To ensure that I understand your response correctly, could you please clarify your answer once again, or select the appropriate option from the given choices?.",
                            { delay: 1000, widget: "option-location" }
                        );
                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage]
                        }))
                        callback(null);
                    }
                }
                else if(meta.steps.current === 4){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("Hello there! I'm sorry, I didn't quite catch that. Could you please select your pick-up date and time from the drop-down options provided? This will help us ensure accurate scheduling for your rental. Thank you",
                        {widget: 'date-time-pick-field', delay: 1000})
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                }
                else if(meta.steps.current === 5){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("Hello! I'm sorry, but I couldn't understand your response. Could you please select your desired drop-off date and time from the dropdown menu provided? This will help me ensure that your rental reservation is accurate and meets your needs. Thank you for your assistance!",
                        {widget: 'date-time-drop-field', delay: 1000})
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                }
                else if(meta.steps.current === 6){
                    handler.stopLoader(props);
                    const message = text && text.toLowerCase();
                    if(message.includes("no") || message.includes("nah") || message.includes("nada") || message.includes("nope")){
                        const botMessage = createChatBotMessage("Which age group do you belong to? Please select one of the following options:", {
                            delay: 1000,
                            widget: "select-age-option",
                        });
                        setState((prev) => ({
                            ...prev,
                            meta: Object.assign(prev.meta,{steps: {current: 7, previous: prev.meta.steps.current}}),
                            messages: [...prev.messages, botMessage],
                        }));
                        callback(null);
                    }
                    else if(message.includes("yes") || message.includes("yep") || message.includes("yeah")){
                        const botMessage = createChatBotMessage("I'm having trouble understanding your message. Please choose one from the provided options. This will help me ensure that your reservation is accurate.", {
                            delay: 1000,
                            widget: "option-age",
                        });
                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage],
                        }))
                        callback(null);
                    }
                    else {
                        let botMessage = createChatBotMessage(
                            "I apologize for any confusion. I'm not sure if you mean 'yes' or 'no'. To ensure that I understand your response correctly, could you please clarify your answer once again, or select the appropriate option from the given choices?.",
                            { delay: 1000, widget: "option-age" }
                        );
                        setState((prev) => ({
                            ...prev,
                            messages: [...prev.messages, botMessage]
                        }))
                        callback(null);
                    }
                }
                else if(meta.steps.current === 7){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("To better understand your needs, may I know your age group? Please select one of the following options:",
                        {delay: 1000,widget: "select-age-option" })
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                    callback(null);

                }
                else if(meta.steps.current === 8){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("I'm sorry, but I couldn't understand your response. To rent a car, please select your desired car from the rental car results provided. ",
                        {delay: 1000, widget: "car-list" })
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                    callback(null);
                }
                else if(meta.steps.current === 9){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("I'm sorry, but I'm having trouble understanding your message. If you were trying to select an add-on for your rental car, please choose one from the list provided. This will help me ensure that your reservation is accurate and meets your needs. ",
                        {delay: 1000, widget: "add-on-default" })
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                    callback(null);
                }
                else if(meta.steps.current === 10){
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage("I'm sorry, but I'm having trouble understanding your message. If you were trying to select an add-on for your rental car, please choose one from the list provided. This will help me ensure that your reservation is accurate and meets your needs. ",
                        {delay: 1000, widget: "add-on-list" })
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                    callback(null);
                }
                else {
                    handler.stopLoader(props);
                    let botMessage = createChatBotMessage(
                        "I'm not sure what you mean, but I’m still learning so please let me try again. A short, direct sentence or keyword is easiest for me to understand.",
                        { delay: 1000 }
                    );
                    setState((prev) => ({
                        ...prev,
                        messages: [...prev.messages, botMessage]
                    }))
                    callback(null);
                }
            }

        ], function(err, result){

        })

    };

    const handleResetMeta = (option) => {
        setState((prev) => ({
            ...prev,
            messages: [],
            meta: Object.assign(prev.meta, {
                pickUpCode: null,
                dropUpCode: null,
                pickUpDate: null,
                pickUpTime: null,
                dropUpDate: null,
                dropUpTime: null,
                age: null,
                progress:0,
                selectedCar: {},
                selectedAddOn: {},
                customerDetails: {},
                otherInformation: {},
                completed: false,
                steps: {current: 1, previous: 0},
                ...option,
            }),
            car_list: {},
        }));
    };

    // Put the handleHello and handleDog function in the actions object to pass to the MessageParser
    return React.Children.map(children, (child) =>
        React.cloneElement(child, {
            actions: {
                handleCustomWidgets,
                handlePickUpCode,
                handleDropUpCode,
                handlePickUpDropUpCode,
                handleDatePick,
                handleTimePick,
                handleDateTimePick,
                handleDateDrop,
                handleTimeDrop,
                handleDateTimeDrop,
                handleAge,
                handleStartLoader,
                handleStopLoader,
                handleAddOnList,
                handleCarList,
                handleResetMeta,
                handleHello,
                handleTime,
                handleSelect,
                handleDefault,
                handleResult,
                handleError,
                handleCompleted,
                handleDropOffOption,
                handleDob,
                handleState,
                handleAddOnOption,
                handleSelectField,
                handleAgeOption,
                handleDefaultAddOnOption
            },
        })
    );
};

export default ActionProvider;
