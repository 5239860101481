import React, { useEffect, useState } from "react";
import { DatePicker, Col, TimePicker, Button } from "antd";
import moment from "moment";
import handler from "./handler";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { RightOutlined } from "@ant-design/icons";

dayjs.extend(customParseFormat);

const DateTimeDrop = (props) => {
    const { setState, actionProvider, meta, state, disable } = props;
    let [date, setDate] = useState(dayjs(meta.pickUpDate).add(1, "days"));
    let [time, setTime] = useState(dayjs("12:00", "HH:mm P"));

    const handleTime = (text) => {
        setTime(text);
    };
    const handleDate = (text) => {
        setDate(text);
    };
    const handleOk = () => {
        setState((state) => ({
            ...state,
            meta: Object.assign(meta, {
                dropUpDate: date.format("YYYY-MM-DD"),
                dropUpTime: time ? time.format("HH:mm") : "12:00",
                steps: { current: 6, previous: meta.steps.current },
            }),
        }));
        props.meta.steps = { current: 6, previous: meta.steps.current };
        handler(props);
    };

    return (
        <Col className="w-full">
            <DatePicker
                className="w-full mb-1"
                placeholder={"Select drop-off date"}
                onChange={handleDate}
                format={"DD-MM-YYYY"}
                use12Hours={true}
                disabledDate={(current) =>
                    current && current < moment(meta.pickUpDate).endOf("day")
                }
                defaultValue={date}
                size="large"
                disabled={disable}
            />
            <TimePicker
                className="w-full"
                placeholder={"Select drop-off time"}
                showSecond={false}
                onChange={handleTime}
                format={"hh:mm A"}
                use12Hours={true}
                minuteStep={30}
                defaultValue={time}
                size="large"
                disabled={disable}
            />
            <Button
                disabled={disable}
                onClick={handleOk}
                className="mt-2"
                shape="round"
                type="primary-outline"
                size="large"
            >
                Continue
            </Button>
        </Col>
    );
};

export default DateTimeDrop;
