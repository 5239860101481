import {useEffect} from "react";
import "./App.scss";
// import "./input.scss";
import config from "./chatbot/config.js";
import messageParser from "./chatbot/MessageParser.js";
import actionProvider from "./chatbot/ActionProvider.js";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { Col, Row } from "antd";
function App() {

    useEffect(() => {
        const chatbotContainer = document.querySelector(
            ".react-chatbot-kit-chat-input-container"
        );
        chatbotContainer.classList.add("hidden");
        console.log("chatbotContainer.classList", chatbotContainer.classList);
    }, []);
    return (
        <Row className="App pt-36" align="middle" justify="center">
            <Col xs={24} sm={0} md={0} lg={0}>
                <Row className="text-4xl font-bold text-white text-center">
                    Let's Put You in the Driver's Seat
                </Row>
            </Col>

            <Col xs={0} sm={0} md={0} lg={8} />

            <Col xs={24} sm={16} md={16} lg={8}>
                <Row align="middle" justify="center">
                    <Col className="app-container">
                        <Chatbot
                            config={config}
                            actionProvider={actionProvider}
                            messageParser={messageParser}
                        />
                    </Col>
                </Row>
            </Col>

            <Col xs={0} sm={8} md={8} lg={8} className="h-1/2 pl-10">
                <Row className="text-4xl tracking-wider font-bold text-white mb-3">
                    LET'S PUT YOU
                </Row>
                <Row className="text-4xl tracking-wider font-bold text-white mb-3">
                    IN THE
                </Row>
                <Row className="text-4xl tracking-wider font-bold text-white mb-3">
                    DRIVER'S SEAT.
                </Row>
            </Col>
        </Row>
    );
}

export default App;
