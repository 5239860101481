import axios from 'axios';
import moment from 'moment';

function AddOn(props){
    return new Promise((resolve, reject)=>{
        const {pickUpCode, dropUpCode, age, pickUpDate, dropUpDate, pickUpTime, dropUpTime} = props;
        let data = {
            pickUpCode: pickUpCode.Code,
            dropUpCode: dropUpCode.Code,
            age: age.value,
            pickUpDate: moment(pickUpDate +' '+ pickUpTime).format("YYYY-MM-DDThh:mm:00"),
            dropUpDate: moment(dropUpDate +' '+ dropUpTime).format("YYYY-MM-DDThh:mm:00"),
            countryCode: "GB"
        }
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://easycontent-dev.ibism.com/api/v1/hertz/addOn',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios.request(config)
            .then((response) => {
                resolve(response?.data)
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    })

}
export default AddOn;


