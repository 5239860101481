import React, { useEffect, useState } from "react";
import { Select, Col } from "antd";
import locations from "../location.json";
import handler from "./handler";
import openai from "../api/openai";
import { EnvironmentOutlined } from "@ant-design/icons";

const SelectPickField = (props) => {
    const {
        setState,
        actionProvider,
        meta,
        options,
        source_list,
        destination_list,
        disable,
    } = props;
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(false);
    const [val, setValue] = useState("");
    const [source, setSource] = useState(
        source_list && source_list.length > 0 ? source_list : locations
    );
    useEffect(() => {
        if (location && location.pickUpCode) {
            setState((state) => ({
                ...state,
                meta: Object.assign(meta, location),
            }));
            if (location.pickUpCode && meta && meta.dropUpCode) {
                actionProvider.handleHello(
                    `${location.pickUpCode.Name.split(",")[0]} ➜ ${
                        meta.dropUpCode.Name.split(",")[0]
                    }`
                );


                // actionProvider.handleDateTimePick("When do you intend to make this booking? Could you please specify your pick-up date and time?", {delay: 2000})
            }
            handler(props);
            // if(location.pickUpCode && meta && !meta.dropUpCode){
            //     actionProvider.handleDropUpCode(`My records show that there are multiple drop off locations for your input Please select which location you're referring to ?`, 'select-drop-field' )
            // }
        }
    }, [location]);
    useEffect(()=>{
        if(val){
            const getData = setTimeout(() => {
                setLoading(true);
                console.log("search:", val);
                openai(
                    "https://easycontent-dev.ibism.com/api/v1/hertz/location",
                    val
                )
                    .then((result) => {
                        if (result.meta.success) {
                            setSource(result.data.d);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false);
                        setSource([]);
                    });
            }, 3000)
            return () => clearTimeout(getData)
        }
    }, [val])

    const handlePickUp = (value, obj) => {
        console.log(`selected ${value}`, meta);
        if(meta.dropUpCode){
            setLocation({
                ...location,
                pickUpCode: obj,
                steps: { current: 4, previous: meta.steps.current },
            });
        } else {
            setLocation({
                ...location,
                pickUpCode: obj,
                steps: { current: 2, previous: meta.steps.current },
            });
        }
    };

    const onSearch = (value) => {
        console.log('++++++')
        setValue(value);
    };
    return (
        <Col className="w-full">
            <Select
                className="w-full mb-1"
                showSearch
                placeholder="Search your pick-up location"
                suffixIcon={<EnvironmentOutlined className="text-xl" />}
                optionFilterProp="children"
                onChange={handlePickUp}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                loading={loading}
                value={location?.pickUpCode?.label}
                options={source.map((o) => {
                    return {
                        ...o,
                        label: o.Name,
                        value: o.Code,
                    };
                })}
                disabled={disable}
                size="large"
            />
        </Col>
    );
};

export default SelectPickField;
