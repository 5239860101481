import React, { useMemo, useState } from "react";
import { Button, Card, Col, Drawer, Row } from "antd";
import moment from "moment";
import { useChatbot } from "react-chatbot-kit";
import messageParser from "../MessageParser";
import progress from "../progress";
import {
    AppstoreAddOutlined,
    UpOutlined,
    DownOutlined,
    CarFilled,
    EnvironmentOutlined,
    HourglassOutlined,
} from "@ant-design/icons";

const AppHeader = ({ config, actionProvider }) => {
    const { state } = useChatbot({ config, actionProvider, messageParser });
    const [showBookingDetails, setShowBookingDetails] = useState(false);

    const {
        dropUpCode,
        pickUpCode,
        pickUpDate,
        pickUpTime,
        dropUpDate,
        dropUpTime,
        age,
        selectedCar,
        selectedAddOn,
    } = useMemo(() => state?.meta, [state?.meta]);
    console.log(state?.meta);
    const progressPercent = () => {
        const steps = progress(state?.meta || {});
        const totalSteps = 100;

        return ((steps / totalSteps) * 100).toFixed(2) + "%";
    };

    const vehicleInfo = selectedCar;

    let totalPrice = 0;
    if (selectedCar?.vehiclecharges?.total) {
        totalPrice += Number(selectedCar?.vehiclecharges?.total);
        Object.keys(selectedAddOn).forEach((o) => {
            if (selectedAddOn[o]?.price) {
                let tax =
                    Number(selectedAddOn[o].price) +
                    (Number(selectedAddOn[o].price) * 20) / 100;
                totalPrice += tax * selectedAddOn[o].quantity;
            } else {
                totalPrice += Number(selectedAddOn[o].totalwithtax);
            }
        });
    }

    return (
        <React.Fragment>
            <Col className="app-header-container ">
                <Row className="bg-white rounded-t-lg px-2 py-2">
                    <img
                        className="h-7 w-auto"
                        src="/logo.svg"
                        alt="app logo"
                    />
                </Row>

                {pickUpCode && (
                    <>
                        <Col className="w-full">
                            <Row className="bg-black">
                                <Row
                                    className="h-1 progress-bar"
                                    style={{ width: progressPercent() }}
                                />
                            </Row>

                            <Row
                                className="booking-summary-container"
                                align="middle"
                            >
                                <Col className="px-4 py-2" span={12}>
                                    <Row className="text-xs">
                                        {pickUpCode?.Name}
                                    </Row>
                                    <Row className="text-2xl">
                                        {moment(pickUpDate).isValid()
                                            ? moment(pickUpDate).format(
                                                  "DD MMM"
                                              )
                                            : "-"}
                                    </Row>
                                    <Row className="text-xs">
                                        {pickUpTime
                                            ? moment(
                                                  `${pickUpDate} ${pickUpTime}`
                                              ).format("hh:mm A")
                                            : "-"}
                                    </Row>
                                </Col>

                                <Col className="px-4 py-2" span={12}>
                                    <Row className="text-xs">
                                        {dropUpCode?.Name}
                                    </Row>
                                    <Row className="text-2xl">
                                        {moment(dropUpDate).isValid()
                                            ? moment(dropUpDate).format(
                                                  "DD MMM"
                                              )
                                            : "-"}
                                    </Row>
                                    <Row className="text-xs">
                                        {dropUpTime
                                            ? moment(
                                                  `${pickUpDate} ${dropUpTime}`
                                              ).format("hh:mm A")
                                            : "-"}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Button
                            className="absolute -bottom-2 right-4 z-10"
                            shape="circle"
                            icon={<DownOutlined />}
                            onClick={() => setShowBookingDetails(true)}
                        />
                    </>
                )}

                <Row className="h-px bg-slate-300" />
            </Col>

            {showBookingDetails && (
                <Col className="booking-details-container">
                    <Drawer
                        className="app-drawer"
                        title={null}
                        placement={"top"}
                        onClose={() => setShowBookingDetails(false)}
                        open={showBookingDetails}
                        getContainer={false}
                        height={550}
                        closable={false}
                    >
                        <Button
                            className="absolute -bottom-2 right-4 z-10"
                            shape="circle"
                            icon={<UpOutlined />}
                            onClick={() => setShowBookingDetails(false)}
                        />

                        {pickUpCode && (
                            <Card className="mb-2">
                                <Col className="text-sm font-bold text-primary">
                                    <Row align="middle">
                                        <EnvironmentOutlined className="text-xl mr-1" />
                                        Pick Up time
                                    </Row>
                                </Col>
                                <Col className="text-sm">
                                    {pickUpCode?.Name}
                                </Col>

                                {pickUpDate && (
                                    <Col className="text-xl">
                                        {moment(pickUpDate).isValid()
                                            ? moment(pickUpDate).format(
                                                  "DD MMM YY"
                                              )
                                            : "-"}
                                    </Col>
                                )}

                                {pickUpTime && (
                                    <Col className="text-sm">
                                        {moment(
                                            `${pickUpDate} ${pickUpTime}`
                                        ).isValid()
                                            ? moment(
                                                  `${pickUpDate} ${pickUpTime}`
                                              ).format("hh:mm A")
                                            : "-"}
                                    </Col>
                                )}
                            </Card>
                        )}

                        {dropUpCode && (
                            <Card className="mb-3">
                                <Col className="text-sm font-bold text-primary">
                                    <Row align="middle">
                                        <EnvironmentOutlined className="text-xl mr-1" />
                                        Return time
                                    </Row>
                                </Col>

                                <Col className="text-sm">
                                    {dropUpCode?.Name}
                                </Col>

                                {dropUpDate && (
                                    <Col className="text-xl">
                                        {moment(dropUpDate).isValid()
                                            ? moment(dropUpDate).format(
                                                  "DD MMM YY"
                                              )
                                            : "-"}
                                    </Col>
                                )}

                                {dropUpTime && (
                                    <Col className="text-sm">
                                        {moment(
                                            `${dropUpDate} ${dropUpTime}`
                                        ).isValid()
                                            ? moment(
                                                  `${dropUpDate} ${dropUpTime}`
                                              ).format("hh:mm A")
                                            : "-"}
                                    </Col>
                                )}
                            </Card>
                        )}

                        {age && (
                            <Card className="mb-3">
                                {age && (
                                    <Col>
                                        <Col className="text-sm font-bold text-primary">
                                            <Row align="middle">
                                                <HourglassOutlined className="text-xl mr-1" />
                                                Your Age
                                            </Row>
                                        </Col>

                                        <Col className="text-xl">
                                            {age?.label}
                                        </Col>
                                    </Col>
                                )}
                            </Card>
                        )}

                        {Object.keys(selectedCar).length > 0 && (
                            <Card className="mb-3">
                                {selectedCar && (
                                    <Col className="mb-2">
                                        <Col className="text-sm font-bold text-primary">
                                            <CarFilled className="text-xl mr-1" />
                                            Car
                                        </Col>

                                        {selectedCar?.pictureurl && (
                                            <Col className="text-center">
                                                <img
                                                    className="h-36 bg-auto bg-center"
                                                    src={
                                                        selectedCar?.pictureurl
                                                    }
                                                    alt="Car"
                                                />
                                            </Col>
                                        )}

                                        <Col className="text-lg">
                                            {selectedCar?.name}
                                        </Col>

                                        <Col className="py-2 my-3 border-x-0 border-y-0.5 border-solid border-y-stone-500 mb-3">
                                            <Row
                                                className="w-full"
                                                align="middle"
                                                justify="center"
                                            >
                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/person.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                vehicleInfo?.passengerquantity ??
                                                                "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>
                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/feature_suitcase.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                vehicleInfo?.baggagequantity ??
                                                                "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>

                                                <Col span={8}>
                                                    <Row
                                                        align="middle"
                                                        justify="center"
                                                    >
                                                        <img
                                                            className="h-7 w-7"
                                                            src="https://images.hertz.com/content/dam/irac/Overlay/icons/feature_transmission.png"
                                                            alt="person"
                                                        />

                                                        <span className="font-semibold">
                                                            {` ${
                                                                vehicleInfo?.transmissiontype
                                                                    ? `${vehicleInfo?.transmissiontype}`.charAt(
                                                                          0
                                                                      )
                                                                    : "-"
                                                            }`}
                                                        </span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {selectedCar?.vehiclecharges?.charges &&
                                            selectedCar?.vehiclecharges?.charges.map(
                                                (o) => (
                                                    <Row>
                                                        <Col
                                                            span={16}
                                                            className="text-base l"
                                                        >
                                                            <Row align="middle">
                                                                <span className="truncate mr-1">
                                                                    {o.purpose}
                                                                </span>
                                                            </Row>
                                                        </Col>

                                                        <Col
                                                            span={8}
                                                            className="text-right font-bold"
                                                        >
                                                            {`${o.amount} ${selectedCar?.vehiclecharges?.currency}`}
                                                        </Col>
                                                        <Col
                                                            span={16}
                                                            className="text-base l"
                                                        >
                                                            <Row align="middle">
                                                                <span className="truncate mr-1">
                                                                    {`Tax (${
                                                                        o.taxpercentage ||
                                                                        0
                                                                    }%)`}
                                                                </span>
                                                            </Row>
                                                        </Col>

                                                        <Col
                                                            span={8}
                                                            className="text-right font-bold"
                                                        >
                                                            {`${
                                                                o.taxamounts ||
                                                                (0).toFixed(2)
                                                            } ${
                                                                selectedCar
                                                                    ?.vehiclecharges
                                                                    ?.currency
                                                            }`}
                                                        </Col>
                                                    </Row>
                                                )
                                            )}

                                        {selectedCar?.vehiclecharges?.fees &&
                                            selectedCar?.vehiclecharges?.fees.map(
                                                (o) => (
                                                    <Row>
                                                        <Col
                                                            span={16}
                                                            className="text-base l"
                                                        >
                                                            <Row align="middle">
                                                                <span className="truncate mr-1">
                                                                    {o?.description &&
                                                                        o?.description
                                                                            .charAt(
                                                                                0
                                                                            )
                                                                            .toUpperCase() +
                                                                            o?.description
                                                                                .slice(
                                                                                    1
                                                                                )
                                                                                .toLowerCase()}
                                                                </span>
                                                            </Row>
                                                        </Col>

                                                        <Col
                                                            span={8}
                                                            className="text-right font-bold"
                                                        >
                                                            {o?.amount +
                                                                " " +
                                                                o?.currencycode}
                                                        </Col>
                                                    </Row>
                                                )
                                            )}
                                    </Col>
                                )}
                            </Card>
                        )}

                        {Object.keys(selectedAddOn).length > 0 && (
                            <Card className="mb-3">
                                {selectedAddOn && (
                                    <Col className="mb-2">
                                        <Col className="text-sm font-bold text-primary">
                                            <Row align="middle">
                                                <AppstoreAddOutlined className="text-xl mr-1" />
                                                Add ons
                                            </Row>
                                        </Col>

                                        {Object.values(selectedAddOn).map(
                                            (addOn) =>
                                                addOn?.quantity > 0 && (
                                                    <Col>
                                                        <Row>
                                                            <Col
                                                                span={16}
                                                                className="text-base"
                                                            >
                                                                <Row align="middle">
                                                                    <span className="truncate mr-1">
                                                                        {`${
                                                                            addOn?.title ||
                                                                            addOn?.name
                                                                        } `}
                                                                    </span>

                                                                    <span className="font-bold whitespace-normal">
                                                                        (x
                                                                        {
                                                                            addOn?.quantity
                                                                        }
                                                                        )
                                                                    </span>
                                                                </Row>
                                                            </Col>

                                                            <Col
                                                                span={8}
                                                                className="text-right font-bold"
                                                            >
                                                                {`${(
                                                                    (addOn?.price ||
                                                                        addOn.total) *
                                                                    addOn?.quantity
                                                                ).toFixed(
                                                                    2
                                                                )}  
                                                                    ${selectedCar?.vehiclecharges?.currency
                                                                }`}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col
                                                                span={16}
                                                                className="text-base"
                                                            >
                                                                <Row align="middle">
                                                                    <span className="truncate">
                                                                        Tax
                                                                        Amount
                                                                    </span>
                                                                </Row>
                                                            </Col>

                                                            <Col
                                                                span={8}
                                                                className="text-right font-bold"
                                                            >
                                                                {`${(
                                                                    ((addOn?.price ||
                                                                        addOn.total) *
                                                                        addOn?.quantity *
                                                                        20) /
                                                                    100
                                                                ).toFixed(
                                                                    2
                                                                )}   ${selectedCar?.vehiclecharges?.currency}`}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )
                                        )}
                                    </Col>
                                )}
                            </Card>
                        )}

                        {selectedCar?.vehiclecharges?.currency && (
                            <Card className="mb-3 bg-gray-100">
                                {
                                    <Row>
                                        <Col
                                            span={12}
                                            className="text-lg font-bold text-primary"
                                        >
                                            Pay at location
                                        </Col>
                                        <Col
                                            span={12}
                                            className="text-lg text-right font-bold"
                                        >
                                            {totalPrice.toFixed(2) +
                                                " " +
                                                selectedCar?.vehiclecharges
                                                    ?.currency}
                                        </Col>
                                    </Row>
                                }
                            </Card>
                        )}
                    </Drawer>
                </Col>
            )}
        </React.Fragment>
    );
};

export default AppHeader;
