import React, { useState } from "react";
import { Card, Button, Col, Row } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    RightOutlined,
} from "@ant-design/icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

let addOnList = [
    {
        id: "4",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/SunHat-3.jpg",
        title: "Ski Rack",
        description: "Eliminate your excess and protect yourself against collisions, theft and accidental damage.",
        price: "779.74",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
    {
        id: "7",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/InfantSeat_New.jpg",
        title: "Infant Child Seats",
        description: "For infants less than one year and under 13kg.",
        price: "136.80",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
    {
        id: "8",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/ChildSeat_New.jpg",
        title: "Child Toddler Seats",
        description: "Front facing for children weighing between 9 and 25kg.",
        price: "136.80",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
    {
        id: "13",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/ChildSeat_New.jpg",
        title: "Hertz NeverLost/portable GPS",
        description: "Front facing for children weighing between 9 and 25kg.",
        price: "136.80",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
    {
        id: "14",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/ChildSeat_New.jpg",
        title: "Snow Tires",
        description: "Front facing for children weighing between 9 and 25kg.",
        price: "136.80",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
    {
        id: "9",
        img: "https://images.hertz.com/content/dam/irac/Overlay/vasImages/BoosterSeat_New.jpg",
        title: "Highback Booster Car Seats",
        description: "For children weighing between 9 and 36kg.",
        price: "136.80",
        currency: "GBP",
        rate: "Item per rental",
        quantity: 0,
    },
];

const AddOnList = (props) => {
    const { setState, actionProvider, meta, available_add_on, disable } = props;
    // Quantity State
    // const [quantity, setQuantity] = useState(0);
    const [addOnSelection, setAddOnSelection] = useState({});
    addOnList = addOnList
        .map((o) => {
            if (available_add_on[o.id]?.price) {
                o.price = available_add_on[o.id].price;
                o.currencycode = available_add_on[o.id].currencycode;
                return o;
            } else {
                return null;
            }
        })
        .filter((o) => o);
    const [addOn, setAddOn] = useState(addOnList);

    // Increase Quantity
    const AddItems = (o) => {
        // setQuantity(quantity => quantity + 1);
        o.quantity = o.quantity + 1;
        let newList = addOn.map((i) => {
            if (i.id === o.id) {
                i.quantity = o.quantity;
            }
            return i;
        });
        setAddOn(newList);
        if (!addOnSelection[o.id]) {
            addOnSelection[o.id] = {};
        }
        addOnSelection[o.id] = o;
        setAddOnSelection(addOnSelection);
    };

    // Decrease Quantity
    const DecreaseItems = (o) => {
        if (o.quantity > 0) {
            o.quantity = o.quantity - 1;
            let newList = addOn.map((i) => {
                if (i.id === o.id) {
                    i.quantity = o.quantity;
                }
                return i;
            });
            setAddOn(newList);
            if (!addOnSelection[o.id]) {
                addOnSelection[o.id] = {};
            }
            addOnSelection[o.id] = o;
            setAddOnSelection(addOnSelection);
        }
    };

    const handleAddOnSelect = () => {
        setState((state) => ({
            ...state,
            meta: Object.assign(meta, {
                selectedAddOn: Object.assign(
                    meta.selectedAddOn,
                    addOnSelection
                ),
                steps: {current: 11, previous: meta.steps.current}
            }),
        }));
        actionProvider.handleResult(
            "Thank you for taking the time to share your information. We have successfully saved it.",
            { delay: 1000 }
        );
        // actionProvider.handleStartLoader(props);
        actionProvider.handleCompleted();
        actionProvider.handleResult(
            "Please refer to the summary provided above.",
            { delay: 1000 }
        );
    };
    return (
        <React.Fragment>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={16}
                slidesPerView={1}
                navigation
                // pagination
            >
                {addOn.map((o, i) => {
                    const price = (Number(o.price) + (Number(o.price) * 20/100)).toFixed(2)
                    return (
                        <SwiperSlide key={i}>
                            <Card
                                className="add-on-details-card h-88"
                                bordered={false}
                                cover={
                                    <Col className="text-center">
                                        <img
                                            className="h-36 w-auto max-w-full bg-cover bg-center object-cover"
                                            src={o.img}
                                            alt={o.title}
                                        />
                                    </Col>
                                }
                            >
                                <Col className="flex flex-col h-40">
                                    <Col className="w-full mb-3">
                                        <Col className="text-base font-bold">
                                            {o.title}
                                        </Col>

                                        <Col className="text-sm font-normal line-clamp-4">
                                            {o.description}
                                        </Col>
                                    </Col>

                                    <div className="flex flex-1" />

                                    <Col className="text-base font-bold mb-3">
                                        {`${price}(${o.currencycode}) - ${o.rate}`}
                                    </Col>

                                    <Row
                                        style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <MinusCircleOutlined
                                            style={{ fontSize: "25px" }}
                                            onClick={() => DecreaseItems(o)}
                                        />
                                        <div className={"ml-5 mr-5"}>
                                            {o.quantity}
                                        </div>
                                        <PlusCircleOutlined
                                            style={{ fontSize: "25px" }}
                                            onClick={() => AddItems(o)}
                                        />
                                    </Row>
                                </Col>
                            </Card>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Row gutter={[5, 5]}>
                <Button
                    onClick={handleAddOnSelect}
                    disabled={disable}
                    // className="mt-2"
                    shape="round"
                    type={"primary-outline"}
                    size="large"
                >
                    Save & Confirm reservation <RightOutlined />
                </Button>

                <Button
                    onClick={handleAddOnSelect}
                    disabled={disable}
                    // className="mt-2"
                    shape="round"
                    type={"primary-outline"}
                    size="large"
                >
                    Skip add-ons
                    <RightOutlined />
                </Button>
            </Row>
        </React.Fragment>
    );
};
export default AddOnList;
