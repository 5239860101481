import React, { useEffect, useState } from "react";
import { Select, Col } from "antd";
import locations from "../location.json";
import handler from "./handler";
import openai from "../api/openai";
import { EnvironmentOutlined } from "@ant-design/icons";

const SelectDropField = (props) => {
    const { setState, actionProvider, meta, destination_list, disable } = props;
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(false);
    const [val, setValue] = useState("");
    const [destination, setDestination] = useState(
        destination_list && destination_list.length > 0
            ? destination_list
            : locations
    );
    useEffect(() => {
        if (location && location.dropUpCode) {
            setState((state) => ({
                ...state,
                meta: Object.assign(meta, location),
            }));
            actionProvider.handleHello(
                `${
                    location.dropUpCode.Name.split(",")[0]
                }, I've always wanted to visit!`,
                { delay: 1000 }
            );
            if (location.dropUpCode && meta && meta.pickUpCode) {
                actionProvider.handleHello(
                    `${meta.pickUpCode.Name.split(",")[0]} ➜ ${
                        location.dropUpCode.Name.split(",")[0]
                    }`,
                    { delay: 2000 }
                );
                // actionProvider.handleDateTimePick("When do you intend to make this booking? Could you please specify your pick-up date and time?", {delay: 3000})
            }
            handler(props);
        }
    }, [location]);
    useEffect(()=>{
        if(val){
            const getData = setTimeout(() => {
                setLoading(true);
                console.log("search:", val);
                openai(
                    "https://easycontent-dev.ibism.com/api/v1/hertz/location",
                    val
                )
                    .then((result) => {
                        if (result.meta.success) {
                            console.log(result.data.d);
                            setDestination(result.data.d);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(false);
                        setDestination([]);
                    });
            }, 2000)
            return () => clearTimeout(getData)
        }
    }, [val])

    const handleDropUp = (value, obj) => {
        console.log(`selected ${value}`);
        setLocation({
            ...location,
            dropUpCode: obj,
            steps: { current: 4, previous: meta.steps.current },
        });
    };

    const onSearch = (value) => {
        console.log('++++++')
        setValue(value);
    };
    return (
        <Col className="w-full">
            <Select
                className="w-full mb-1"
                showSearch
                placeholder="Search your drop-off location"
                suffixIcon={<EnvironmentOutlined className="text-xl" />}
                optionFilterProp="children"
                onChange={handleDropUp}
                onSearch={onSearch}
                loading={loading}
                filterOption={(input, option) =>
                    (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
                options={destination.map((o) => {
                    return {
                        ...o,
                        label: o.Name,
                        value: o.Code,
                    };
                })}
                value={location?.dropUpCode?.label}
                disabled={disable}
                size="large"
            />
        </Col>
    );
};

export default SelectDropField;
