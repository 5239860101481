import React, { useEffect, useState } from "react";
import { Col, TimePicker } from "antd";
import moment from "moment";
import handler from "./handler";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const TimeDrop = (props) => {
    const { setState, actionProvider, meta } = props;

    let [time, setTime] = useState(dayjs('12:00', 'HH:mm P'));

    useEffect(()=>{
        if(time){
            setState((state) => ({
                ...state,
                meta: Object.assign(meta, {
                    dropUpTime: time.format("HH:mm"),
                }),
            }));
            handler(props);
        }
    }, [time])

    const handleTime = (text) => {
        setTime(text)
    }

    return (
        <Col className="w-full">
            <TimePicker
                className="w-full"
                placeholder={"Select drop-off time"}
                showSecond={false}
                onChange={handleTime}
                format={"hh:mm A"}
                defaultValue={time}
                use12Hours={true}
                minuteStep={30}
                size="large"
            />
        </Col>
    );
};

export default TimeDrop;
