import events from "../fields/handler";
import moment from "moment";

export default {
    changeLocation: (props) => {
        const { actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 3, previous: meta.steps.current },
            }),
        });
        events(props);
    },
    sameLocation: (props) => {
        const { actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 4, previous: meta.steps.current },
                dropUpCode: meta.pickUpCode,
            }),
        });
        events(props);
    },
    handlePickDropDate: (props) => {
        const { setState, actionProvider, meta, options } = props;
        const payload = {
            pickUpCode: meta.pickUpCode,
            dropUpCode: meta.dropUpCode,
            steps: { current: 4, previous: meta.current },
        };
        actionProvider.handleResetMeta(payload);

        actionProvider.handleStartLoader();
        setTimeout(() => {
            actionProvider.handleStopLoader();
            events(props);
        }, 2000);
    },
    handleBooking: (props) => {
        const { setState, actionProvider, meta, options } = props;
        const payload = {};
        actionProvider.handleResetMeta(payload);
        actionProvider.handleStartLoader();
        setTimeout(() => {
            actionProvider.handleStopLoader();
            events(props);
        }, 2000);
    },
    handleStartBooking: (props) => {
        const { setState, actionProvider, meta, options } = props;
        const payload = {};
        actionProvider.handleResetMeta(payload);

        actionProvider.handleStartLoader();
        setTimeout(() => {
            actionProvider.handleStopLoader();
            events(props);
        }, 2000);
    },
    initiateReservation: (props) => {
        const { setState, actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 1, previous: meta.steps.current },
            }),
        });
        events(props);
    },
    initiateFaq: (props) => {
        const { setState, actionProvider, meta } = props;
        const chatbotContainer = document.querySelector(
            ".react-chatbot-kit-chat-input-container"
        );
        chatbotContainer.classList.remove("hidden");
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: -1, previous: meta.steps.current },
            }),
        });
        events(props);
    },
    ageOptionYes: (props) => {
        const { setState, actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 8, previous: meta.steps.current },
                age: {label: "30+", value: moment().subtract(31, "years").format("YYYY-MM-DD")},
            }),
        });
        events(props);
    },
    ageOptionNo: (props) => {
        const { setState, actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 7, previous: meta.steps.current },
            }),
        });
        events(props);
    },
    addOnOptionYes: (props) => {
        const { setState, actionProvider } = props;
        setState((prev) => {
            return {
                ...prev,
                meta: Object.assign(prev.meta, {
                    addOnOption: true,
                }),
            };
        });
        actionProvider.handleAddOnList("Make your rental even better");
    },
    addOnOptionNo: (props) => {
        const { setState, actionProvider } = props;
        setState((prev) => {
            return {
                ...prev,
                meta: Object.assign(prev.meta, {
                    addOnOption: false,
                }),
            };
        });
        actionProvider.handleResult(
            "Thank you for taking the time to share your information. We have successfully saved it.",
            { delay: 1000 }
        );
        // actionProvider.handleStartLoader(props);
        actionProvider.handleCompleted();
        actionProvider.handleResult(
            "Please refer to the summary below. Cheers!",
            { delay: 1000 }
        );
    },
    handleAgeOption: (props, age) => {
        const { setState, actionProvider, meta } = props;
        actionProvider.handleState({
            meta: Object.assign(meta, {
                steps: { current: 8, previous: meta.steps.current },
                age: age,
            })
        });
        events(props);
        // actionProvider.handleHello("Hang tight! We are getting the best result for you..",{});
        // actionProvider.handleStartLoader()
        // checkStatus(meta).then((result) => {
        //     actionProvider.handleStopLoader()
        //     if (result?.data?.ota_vehavailraters?.errors?.error) {
        //         actionProvider.handleHello(
        //             result?.data?.ota_vehavailraters?.errors?.error
        //                 ?.shorttext
        //         );
        //         actionProvider.handleError("You can select a topic below ", {delay:2000})
        //     } else {
        //         addOn(meta).then(result=>{
        //             setState((state) => ({
        //                 ...state,
        //                 available_add_on: result?.data,
        //             }));
        //         }).catch(err=>{
        //             console.log(err);
        //         })
        //
        //         setState((state) => ({
        //             ...state,
        //             car_list: result?.data?.ota_vehavailraters,
        //         }));
        //         actionProvider.handleStopLoader()
        //
        //         actionProvider.handleCarList(
        //             "Choose a car?"
        //         );
        //     }
        // });
    },
};
