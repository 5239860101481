import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import handler from "../fields/handler";

const Options = (props) => {
    const { options, disable } = props;
console.log(disable)
    return (
        <Row gutter={[12, 8]}>
            {options.map((o, i)=>{
                return(
                    <Button
                        disabled={disable}
                        key={i}
                        className="mr-1"
                        onClick={o.handle}
                        type="primary-outline"
                        shape="round"
                        size="large"
                    >
                        {o.title}
                    </Button>
                )
            })}
        </Row>
    );
};
export default Options;
