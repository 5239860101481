import React from "react";

const MessageParser = (props) => {
    const { children, actions, meta } = props;
    const parse = (message) => {
        console.log('User Input text: ', message, message.length === 0)
        if(message.length === 0){
            actions.handleHello("Please ask me any thing?")
        } else if (message === "hello" || message === "hi") {
            actions.handleHello();
        } else {
            actions.handleDefault(message);
        }
    };

    return React.Children.map(children, (child) =>
        React.cloneElement(child, {
            parse: parse,
            actions,
        })
    );
};

export default MessageParser;
