import React, { useEffect, useState } from 'react';
import {Select, Col} from 'antd';
import locations from '../location.json'
import handler from "./handler";
import common from './common-func';

const SelectPickDrop = (props) => {
    const { setState, actionProvider, meta, source_list, destination_list, pick_disable } = props;
    const [location, setLocation] = useState({});
    const [source, setSource] = useState((source_list && source_list.length > 0) ? source_list : locations);
    const [destination, setDestination] = useState((destination_list && destination_list.length > 0) ? destination_list : locations);

    useEffect(()=>{
        if(location && location.pickUpCode && location.dropUpCode){
            setState((state) => ({
                ...state,
                meta: Object.assign( meta, location, {steps: {current: 4, previous: state.meta.steps.current}}),
            }));
            actionProvider.handleHello(`${location.dropUpCode.Name.split(',')[0]}, I've always wanted to visit!`, {delay: 1000});
            actionProvider.handleHello(`${location.pickUpCode.Name.split(',')[0]} ➜ ${meta.dropUpCode.Name.split(',')[0]}`,{delay: 1000});
            handler(props)
            // actionProvider.handleDateTimePick("When do you intend to make this booking? Could you please specify your pick-up date and time?", {delay: 3000})
        }
    }, [location])
    useEffect(()=>{
        if(source && source.length === 1){
            handlePickUp(source.Name, source)
        }
        if(destination && destination.length === 1){
            handleDropUp(destination.Name, destination)
        }}, [source, destination])

    const handlePickUp = (value, obj) => {
        console.log(`selected ${value}`);
        setLocation({...location, pickUpCode: obj})
    };
    const handleDropUp = (value, obj) => {
        console.log(`selected ${value}`);
        setLocation({...location, dropUpCode: obj})
    };


    const onSearch = (value) => {
        console.log('search:', value);
    };

    return (
        <Col className="w-full">
            <Select
                className="w-full mb-1"
                showSearch
                disabled={pick_disable}
                placeholder="Pick-up location"
                optionFilterProp="children"
                onChange={handlePickUp}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={source.map(o=>{
                    return {
                        ...o,
                        label: o.Name,
                        value: o.Code
                    }
                })}
                size="large"
            />
            <Select
                className="w-full mb-1"
                showSearch
                disabled={pick_disable}
                placeholder="Drop-off location"
                optionFilterProp="children"
                onChange={handleDropUp}
                onSearch={onSearch}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={destination.map(o=>{
                    return {
                        ...o,
                        label: o.Name,
                        value: o.Code
                    }
                })}
                size="large"
            />
        </Col>
    );
};

export default SelectPickDrop;